/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:29:52 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-Th 09:25:48
 */
<template>
  <div class="personalData">
    <h3 class="personal_title">个人资料</h3>
    <div class="cont">
      <el-row>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>姓名</span>
          <el-input
            v-model="persData.realName"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>电话号码</span>
          <el-input
            v-model="persData.phone"
            placeholder="请输入电话号码"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="user.Status != 0">
          <span class="tit"><span class="c_F20F0F">*</span>身份证号</span>
          <el-input
            v-model="persData.idCardNum"
            placeholder="请输入身份证号"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <span class="tit">推荐人</span>
          <el-input
            v-model="persData.recommenderName"
            placeholder="请输入"
          ></el-input>
        </el-col>
      </el-row>
      <el-row v-if="user.Status != 0">
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>人像面</span>
          <!-- <img
            v-if="persData.idCardBack && user.Status !== -99"
            v-lazy="Imghost + persData.idCardBack"
            class="avatar"
            width="310"
            height="158"
          /> -->
          <el-upload
            class="avatar-uploader"
            :limit="1"
            :headers="imgHeaders"
            :http-request="postImagesUploadPicId"
            :action="imagesUploadPic1 + 'IdCard'"
            :show-file-list="false"
          >
            <img
              v-lazy="Imghost + persData.idCardBack"
              v-if="persData.idCardBack"
              class="sfz1"
              alt=""
            />
            <img
              src="../../assets/images/IdCardjust.png"
              v-else
              class="sfz"
              alt=""
            />
          </el-upload>
        </el-col>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>国徽面</span>
          <!-- <img
            v-if="persData.idCardFront && user.Status !== -99"
            v-lazy="Imghost + persData.idCardFront"
            class="avatar"
            width="310"
            height="158"
          /> -->
          <el-upload
            class="avatar-uploader"
            :limit="1"
            :headers="imgHeaders"
            :http-request="postImagesUploadPicIdF"
            :action="imagesUploadPic1 + 'IdCard'"
            :show-file-list="false"
          >
            <img
              v-lazy="Imghost + persData.idCardFront"
              v-if="persData.idCardFront"
              class="sfz1"
              alt=""
            />
            <img
              src="../../assets/images/IdCardback.png"
              v-else
              class="sfz"
              alt=""
            />
          </el-upload>
        </el-col>
      </el-row>
      <el-row v-if="user.Status != 0">
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>人脸识别</span>
          <!-- 
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
           -->
          <div class="upload_rlsb">
            <!-- <img
              v-if="persData.faceImg && user.Status !== -99"
              v-lazy="Imghost + persData.faceImg"
              class="faceImg"
            /> -->
            <div class="face" @click="faceClick()">
              <img
                :src="
                  persData.faceImg
                    ? Imghost + persData.faceImg
                    : require('../../assets/images/scanFace.png')
                "
                class="rlsb"
                alt=""
              />
              <div class="djsb" v-if="!persData.faceImg">点击进入识别</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>招生教师</span>
          <el-input
            v-model="persData.signTeacherName"
            placeholder="请输入招生教师"
          ></el-input>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>届别</span>
          <el-select v-model="persData.YearStr" placeholder="请选择届别">
            <el-option
              v-for="item in YearStrs"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>报考方向</span>
          <el-select v-model="persData.examDirection" placeholder="请选择方向">
            <el-option
              v-for="item in examDirection"
              :key="item.Id"
              :label="item.Name"
              :value="item.Name"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>原学校</span>
          <el-select
            v-model="persData.originalSchool"
            filterable
            allow-create
            default-first-option
            @change="originalSchoolChange()"
            placeholder="请选择原学校"
          >
            <el-option
              v-for="item in querySchool"
              :key="item.Id"
              :label="item.Name"
              :value="item.Name"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <span class="tit"><span class="c_F20F0F">*</span>所学专业</span>
          <el-select
            v-model="persData.originalMajor"
            filterable
            allow-create
            default-first-option
            placeholder="请选择所学专业"
          >
            <el-option
              v-for="item in originalMajors"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <span class="tit">报考院校</span>
          <el-select
            v-model="persData.examSchool"
            filterable
            allow-create
            default-first-option
            @change="examSchoolChange()"
            placeholder="请选择报考院校"
          >
            <el-option
              v-for="item in querySchool"
              :key="item.Id"
              :label="item.Name"
              :value="item.Name"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <span class="tit">报考专业</span>
          <el-select
            v-model="persData.examMajor"
            filterable
            allow-create
            default-first-option
            placeholder="请选择报考专业"
          >
            <el-option
              v-for="item in examMajors"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="24">
          <span class="tit">培训课程</span>
          <el-select
            class="w_758"
            v-model="trainCourses"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in queryTrainCourse"
              :key="item.Id"
              :label="item.Name"
              :value="item.Name"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="12">
          <span class="tit">紧急人联系姓名</span>
          <el-input
            v-model="persData.emergencyContact"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <span class="tit">电话</span>
          <el-input
            v-model="persData.emergencyPhone"
            placeholder="请输入电话号码"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <span class="tit">邮寄地址</span>
          <el-input
            v-model="persData.mailAddress"
            placeholder="请输入地址"
            class="w_758"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="btn" :span="24">
          <el-button type="primary" @click="defineClick()">完成</el-button>
          <el-button type="primary" @click="resetClick()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 人脸拍照 -->
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postImagesUploadPicStudentface"
      :isFile="true"
    ></camera>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  commonQueryUserInfo,
  baseQueryExamDirection,
  baseQuerySchool,
  baseQueryTrainCourse,
  studentReplenish,
  studentStudentInfoEdit,
} from "@/api/personal";
import { imagesUploadPic, OCRIdCardOcr } from "@/api/api";
import camera from "@/components/canvasCamera.vue";
export default {
  data () {
    return {
      imagesUploadPic1: "",
      Imghost: this.GLOBAL.hostUrl,
      imgHeaders: { "Content-Type": "multipart/form-data" },
      persData: {
        realName: "", //真实姓名
        phone: "", //联系电话
        idCardNum: "", //身份证号码
        idCardBack: "", //人像面
        idCardFront: "", //国徽面
        faceImg: "", //人脸
        emergencyContact: "", //紧急联系人
        emergencyPhone: "", //紧急联系电话
        mailAddress: "", //邮寄地址
        // signTeacherName: "", //报名教师名称
        recommenderName: "", //推荐人姓名
        YearStr: "", //届别，2020
        examDirection: "", //报考方向
        originalSchool: "", //学校
        originalMajor: "", //专业
        examSchool: "", //报考学校
        examMajor: "", //报考专业
        // trainCourse: "", //培训课程,多选英文逗号隔开
      },
      trainCourses: [],
      YearStrs: [],
      querySchool: [],
      queryTrainCourse: [],
      examDirection: [],
      originalMajors: [],
      examMajors: [],
      dialogVisible: false,
      user: {},
    };
  },
  components: {
    camera,
  },
  created () {
    this.user = JSON.parse(this.$store.state.userInfo);
    this.init();
    this.postbaseQuerySchool(); //学校
    this.postbaseQueryTrainCourse(); //培训课程
    this.postbasebaseQueryExamDirection(); //考试方向
    this.postCommonQueryUserInfo();
  },
  mounted () { },
  methods: {
    ...mapActions(["saveUserInfo"]),
    // 获取学生信息
    async postCommonQueryUserInfo () {
      const res = await commonQueryUserInfo();
      if (res.success == true) {
        if (res.response) {
          this.persData.realName = res.response.RealName;
          this.persData.phone = res.response.Phone;
          this.persData.idCardNum = res.response.IdCardNum;
          this.persData.idCardBack = res.response.IdCardBack;
          this.persData.idCardFront = res.response.IdCardFront;
          this.persData.faceImg = res.response.FaceImg;
          this.persData.emergencyContact = res.response.EmergencyContact;
          this.persData.emergencyPhone = res.response.EmergencyPhone;
          this.persData.mailAddress = res.response.MailAddress;
          // this.persData.signTeacherName = res.response.SignTeacherName;
          this.persData.recommenderName = res.response.RecommenderName;
          this.persData.YearStr = res.response.YearStr;
          this.persData.examDirection = res.response.ExamDirection;
          this.persData.originalSchool = res.response.OriginalSchool;
          this.persData.originalMajor = res.response.OriginalMajor;
          this.persData.examSchool = res.response.ExamSchool;
          this.persData.examMajor = res.response.ExamMajor;
          // this.persData.trainCourse = res.response.TrainCourse;
          // let arr = JSON.parse(JSON.stringify(res.response.TrainCourse));
          // this.trainCourses = arr.split(",");
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 重置
    resetClick () {
      if (this.user.Status == 0) {
        this.persData.emergencyContact = "";
        this.persData.emergencyPhone = "";
        this.persData.mailAddress = "";
        this.persData.examDirection = "";
        this.persData.examSchool = "";
        this.persData.examMajor = "";
        this.persData.trainCourse = "";
      } else {
        this.persData.realName = "";
        this.persData.phone = "";
        this.persData.idCardNum = "";
        this.persData.idCardBack = "";
        this.persData.idCardFront = "";
        this.persData.faceImg = "";
        this.persData.emergencyContact = "";
        this.persData.emergencyPhone = "";
        this.persData.mailAddress = "";
        this.persData.signTeacherName = "";
        this.persData.recommenderName = "";
        this.persData.YearStr = "";
        this.persData.examDirection = "";
        this.persData.originalSchool = "";
        this.persData.originalMajor = "";
        this.persData.examSchool = "";
        this.persData.examMajor = "";
        this.trainCourses = [];
        this.persData.trainCourse = "";
      }
    },
    // 完成
    defineClick () {
      if (this.user.Status == 0) {
        this.getStudentStudentInfoEdit();
      } else {
        this.getStudentReplenish();
      }
    },
    //修改信息
    async getStudentStudentInfoEdit () {
      if (this.persData.examDirection == "") {
        this.$message.info("请选择报考方向");
        return false;
      }
      let parm = {
        EmergencyContact: this.persData.emergencyContact,
        EmergencyPhone: this.persData.emergencyPhone,
        MailAddress: this.persData.mailAddress,
        ExamDirection: this.persData.examDirection,
        ExamSchool: this.persData.examSchool,
        ExamMajor: this.persData.examMajor,
        TrainCourse: this.persData.trainCourse,
      };
      const res = await studentStudentInfoEdit(parm);
      if (res.success == true) {
        this.$message.success(res.msg);
        // localStorage.setItem("token", res.response);
        // this.common.users();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 完善信息
    async getStudentReplenish () {
      console.log(this.persData);
      if (!this.persData.realName) {
        this.$message.error("请输入真实姓名");
        return false;
      }
      if (!this.persData.phone) {
        this.$message.error("请输入电话号码");
        return false;
      }
      if (!this.persData.idCardNum) {
        this.$message.error("请输入身份证号");
        return false;
      }
      if (!this.persData.idCardBack) {
        this.$message.error("请输入上传身份证");
        return false;
      }
      if (!this.persData.idCardFront) {
        this.$message.error("请输入上传身份证");
        return false;
      }
      if (!this.persData.faceImg) {
        this.$message.error("请进行人脸识别");
        return false;
      }
      // if (this.persData.emergencyContact == "") {
      //   this.$message.info("请输入紧急人联系姓名");
      //   return false;
      // }
      // if (this.persData.emergencyPhone == "") {
      //   this.$message.info("请输入紧急人联系电话号码");
      //   return false;
      // }
      // if (this.persData.mailAddress == "") {
      //   this.$message.info("请输入地址");
      //   return false;
      // }
      // if (this.persData.signTeacherName == "") {
      //   this.$message.info("请输入招生教师");
      //   return false;
      // }
      // if (this.persData.recommenderName == "") {
      //   this.$message.info("请输入推荐人");
      //   return false;
      // }
      if (!this.persData.YearStr) {
        this.$message.error("请选择届别");
        return false;
      }
      if (!this.persData.examDirection) {
        this.$message.error("请选择报考方向");
        return false;
      }
      if (!this.persData.originalSchool) {
        this.$message.error("请选择原学校");
        return false;
      }
      if (!this.persData.originalMajor) {
        this.$message.error("请选择原学校所学专业");
        return false;
      }
      // if (this.persData.examSchool == "") {
      //   this.$message.info("请选择报考院校");
      //   return false;
      // }
      // if (this.persData.examMajor == "") {
      //   this.$message.info("请选择报考院校报考专业");
      //   return false;
      // }
      // if (this.trainCourse == "") {
      //   this.$message.info("请选择培训课程");
      //   return false;
      // }
      // let arr = JSON.parse(JSON.stringify(this.trainCourses));
      // this.persData.trainCourse = arr.join(",");
      const res = await studentReplenish(this.persData);
      if (res.success == true) {
        this.$message.success(res.msg);
        localStorage.setItem("token", res.response);
        this.imageUrlToBase64(this.GLOBAL.hostUrl + res.response.FaceImg)
        this.common.users();
        //个人信息完善后，返回购买页
        let route = this.$route.query;
        if (route.perfect == 1) {
          setTimeout(() => {
            this.$router.go(-1);
          }, 2 * 1000);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    //异步执行
    imageUrlToBase64 (imageUrl) {
      console.log(imageUrl);
      //一定要设置为let，不然图片不显示
      let image = new Image();
      //解决跨域问题
      image.setAttribute('crossOrigin', 'anonymous');
      // let imageUrl = "http://bigf.cqugeo.cn/landslip/res/defaultImages/default.png";
      image.src = imageUrl + "?" + Math.random()
      //image.onload为异步加载
      image.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);

        var quality = 0.8;
        //这里的dataurl就是base64类型
        let faceImg = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
        localStorage.setItem("faceImgBase64", faceImg)
      }
    },
    // 人脸识别 弹窗关闭
    getClose () {
      this.dialogVisible = false;
    },
    // 人脸识别
    faceClick () {
      this.dialogVisible = true;
    },
    //身份证认证
    async postOCRIdCardOcr (dard, url) {
      let parm = {
        side: dard,
        url: this.Imghost + url,
      };

      if (dard == "front") {
        const res = await OCRIdCardOcr(parm);
        if (res.success == true) {
          this.persData.idCardBack = url;
          this.persData.realName = res.response.name;
          this.persData.idCardNum = res.response.number;
        } else {
          this.$message.error("身份证无法识别，请重新上传");
        }
      } else {
        this.persData.idCardFront = url;
      }
      // this.$message.success("上传成功");
    },
    // 人像面
    async postImagesUploadPicId (param) {
      // console.log(param.file);
      const formData = new FormData();
      formData.append("file", param.file);
      formData.append("progame", "IdCard");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        // this.persData.idCardBack = res.response;
        this.postOCRIdCardOcr("front", res.response);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 国徽面
    async postImagesUploadPicIdF (param) {
      const formData = new FormData();
      formData.append("file", param.file);
      formData.append("progame", "IdCard");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        // this.persData.idCardFront = res.response;
        this.postOCRIdCardOcr("back", res.response);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 人脸识别
    async postImagesUploadPicStudentface (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("progame", "studentface");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        this.dialogVisible = false;
        this.persData.faceImg = res.response;
        console.log(this.persData);
      } else {
        this.$message.error(res.msg);
      }
    },
    //报考院校改变
    examSchoolChange () {
      let arr = this.querySchool.find((it) => {
        if (it.Name == this.persData.examSchool) {
          return it;
        }
      });
      this.persData.examMajor = "";
      if (arr?.MajorName.split(",") == undefined) {
        this.examMajors = [];
      } else {
        this.examMajors = arr?.MajorName.split(",");
      }
    },
    //原学校改变
    originalSchoolChange () {
      let arr = this.querySchool.find((it) => {
        if (it.Name == this.persData.originalSchool) {
          return it;
        }
      });
      this.persData.originalMajor = "";
      if (arr?.MajorName.split(",") == undefined) {
        this.originalMajors = [];
      } else {
        this.originalMajors = arr?.MajorName.split(",");
      }
    },
    init () {
      //届别
      let year = new Date().getFullYear();
      for (let i = 0; i <= 5; i++) {
        this.YearStrs.push(year + i);
      }
      //图片上传地址
      let url = window.location.origin;
      this.imagesUploadPic1 = url + "/api/images/Upload/Pic?progame=";
    },
    //学校
    async postbaseQuerySchool () {
      const res = await baseQuerySchool();
      if (res.success == true) {
        this.querySchool = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    //培训课程
    async postbaseQueryTrainCourse () {
      const res = await baseQueryTrainCourse();
      if (res.success == true) {
        this.queryTrainCourse = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
    //考试方向
    async postbasebaseQueryExamDirection () {
      const res = await baseQueryExamDirection();
      if (res.success == true) {
        this.examDirection = res.response;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.personalData {
  .cont {
    padding: 0 25px;
    .el-row {
      margin: 30px 0;
      .el-col {
        display: flex;
        align-items: center;
        font-size: 18px;
        .tit {
          display: block;
          margin-right: 20px;
          text-align: right;
          width: 130px;
          flex: 1;
        }
        .el-select {
          width: 310px;
          /deep/ .el-input__inner {
            // height: 50px;
          }
        }
        .el-input {
          width: 310px;
          /deep/ input {
            // height: 50px;
            border-color: #dbdada;
          }
        }
        /deep/ .el-upload {
          width: 310px;
          height: 158px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dashed #dbdada;
          border-radius: 5px;
          .avatar {
            width: 100%;
            height: 100%;
          }
          .sfz {
            width: 233px;
            height: 108px;
          }
          .sfz1 {
            width: 100%;
            height: 100%;
          }
          .rlsb {
            width: 100%;
            height: 100%;
          }
        }
        .upload_rlsb {
          width: 310px;
          height: 206px;
          position: relative;
          border: 1px dashed #dbdada;
          .faceImg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .face {
            width: 100%;
            height: 100%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .djsb {
              position: absolute;
              top: 78px;
              left: 0;
              right: 0;
              width: 168px;
              height: 50px;
              line-height: 50px;
              text-align: center;
              margin: 0 auto;
              border-radius: 4px;
              color: #fff;
              font-size: 24px;
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
      .btn {
        margin: 77px 0 55px;
        justify-content: center;
        .el-button {
          width: 140px;
          height: 54px;
          background-color: #1288f4;
          font-size: 20px;
          color: #ffffff;
          border-radius: 10px;
          &:last-child {
            margin-left: 74px;
            border-color: #1288f4;
            color: #1288f4;
            background-color: #fff;
          }
        }
      }
    }
  }
}
</style>